/* ClockDisplay.css */

.clock {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 auto;
    height: auto;
  }
  
  .number-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px; /* Adjust width as needed */
    height: 80px; /* Adjust height as needed */
    font-size: 2em;
    line-height: 1; /* Ensure line height is normal */
    padding-top: 5pxz; /* Adjust padding to fine-tune vertical alignment */
    background-color: #8ca88e;
    border: 1px solid #3b4f52;
    border-radius: 5px;
    margin: 0 4px; /* Adjust margin as needed */
  }
  