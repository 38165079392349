/* Footer.css */

.footer {
    background-color: #283334;
    text-align: center;
    padding: 10px 0;
    width: 100%;
    margin-top: 50vh;
  }
  
  .footer a {
    color: #ffcc00;
    text-decoration: none;
    margin: 0 10px;
  }
  
  .footer a:hover {
    text-decoration: underline;
  }
  
  /* Ensure the body and html take up the full height */
  html, body {
    height: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
  }
  
  /* Ensure the main content takes up the remaining space */
  #root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .main-content {
    flex: 1;
  }