html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #3b4f52; /* Set your desired background color */
  color: #C8C9C8;
  display: flex;
  flex-direction: column;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.App {
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: center;
  background-color: inherit;
  font-family: "Noto Serif Ahom", serif;
  font-weight: 400;
  font-style: normal;
}

.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer {
  background-color: #333;
  color: white;
  text-align: center;
  padding: 20px 0;
  width: 100%;
  margin-top: auto;
}