.topbar-title {
    flex-grow: 1;
    text-align: left;
  }
  
  .topbar-nav ul {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .topbar-nav li {
    margin: 0 10px;
  }
  
  .topbar-nav a {
    color: #C8C9C8; /* Updated color */
    text-decoration: none;
  }
  
  .topbar-nav a:hover {
    text-decoration: underline;
  }
  
  @media (max-width: 960px) {
    .topbar-nav {
      display: none;
    }
  }