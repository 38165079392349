/* WorldClock.css */

body, html {
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.world-clock-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.world-clock-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-bottom: 20vh;
  max-width: 80%; /* Limit the maximum width of the container */
}

.city-clock {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px; /* Add margin to each city-clock element */
}
