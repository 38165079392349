/* Clock.css */

.clock {
    font-size: 2em;
    color: #333;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center; /* Center vertically */
    height: 100%; /* Ensure it takes full height of the container */
    margin-top: -20%;
}

.clock-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #3b4f52;
}

.number-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px; /* Adjust width as needed */
    height: 80px; /* Adjust height as needed */
    font-size: 2em;
    background-color: #8ca88e;
    border: 1px solid #3b4f52;
    border-radius: 5px;
    margin: 0 5px;
    padding-top: 3vh;
}